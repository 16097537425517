import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import LangContext from '../context/LangContext'

export const IndexPageTemplate = ({
  kr,
  en
}) => {
  let { lang } = useContext(LangContext);
  const mul = lang === 'kr' ? kr : en;

  return (
    <div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content is-child main">

                  <div className="content">
                    <div className="head">
                      {mul.top.head}
                    </div>
                    <p className="service">
                      {mul.top.description1}
                      <br /><br />
                      {mul.top.description2}
                      <br /><br />
                      {mul.top.description3}
                      <br />
                      {lang === 'kr' ?
                        <a href="http://www.shinsegae-inc.com/" target="_blank" rel="noopener noreferrer" style={{ fontSize: "1.1rem" }}>
                          신세계아이앤씨 홈페이지 바로가기
                        </a> :
                        <a href="http://www.shinsegae-inc.com/" target="_blank" rel="noopener noreferrer" style={{ fontSize: "1.1rem" }}>
                          Go to Shinsegae I&C's homepage
                        </a>
                      }
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="content">
                    <div className="head">
                      {mul.mid.retail_head}
                    </div>
                    <div className="service">
                      {/* {mul.mid.description4}
                      <br /><br />
                      {mul.mid.description5}
                      <br /><br /> */}
                      {mul.mid.description6}
                      <br /><br />
                      {mul.mid.description7}
                      <br /><br />
                      {lang === 'kr' ?
                        <Link to="/news/2020-02-24-News-INC-Retail-kr">
                          <img src="../img/Home/retail_introduction.jpg" style={{ width: "92%", marginLeft: "2%" }} alt="retail_introduction" />
                        </Link> :
                        <Link to="/news/2020-02-24-News-INC-Retail-en">
                          <img src="../img/Home/retail_introduction.jpg" style={{ width: "92%", marginLeft: "2%" }} alt="retail_introduction" />
                        </Link>}

                      {/* <img src="../img/Home/retail_introduction2.png" style={{ width: "45%", marginLeft: "3%" }} alt="retail_introduction" /> */}
                      <iframe width="865" height="450" src="https://www.youtube.com/embed/lLgK2faQ_uk" frameborder="0" allow="accelerometer; autoplay;
                      encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginLeft: "3.5%" }}></iframe>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="content">
                    {/* <div className="head">
                      {mul.mid.retail_case}
                    </div> */}
                    {/* <div className="service">
                      {mul.mid.description8}
                      <br /><br />
                      {mul.mid.description9}
                      <br /><br />
                      {mul.mid.description10}
                      <br /><br />
                      <img src="../img/Home/retail_case1.png" style={{ width: "45%", marginLeft: "3%" }} alt="retail_introduction" />
                      <img src="../img/Home/retail_case2.png" style={{ width: "42%", marginLeft: "3%" }} alt="retail_introduction" />

                      <br/><br/>
                      <img src="../img/Home/retail_case3.jpg" style={{ width: "90%", marginLeft: "3%" }} alt="retail_introduction" />

                    </div> */}
                  </div>
                  {/* <br />
                  <br /> */}
                  <div className="content">
                    <div className="head">
                      {mul.mid.head}
                    </div>
                    <div className="service">
                      {mul.mid.description1}
                      <br /><br />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "block" }}>
                          <img src="../img/Home/AWS_Badge.jpg" style={{ width: "80%", margin: "0% 4%" }} alt="AWS_Badge" />
                          <br />
                          <p style={{ marginLeft: "20%", fontSize: "0.8em" }}>
                            {mul.mid.img_des1}<br />
                            {mul.mid.img_des2}<br />
                            {mul.mid.img_des3}<br />
                          </p>
                        </div>
                        <div>
                          {lang === 'kr' ?
                            <img src="../img/Home/introduction.png" style={{ width: "100%" }} alt="introduction" /> :
                            <img src="../img/Home/introduction_en.png" style={{ width: "100%" }} alt="introduction" />
                          }
                        </div>
                        <br />
                      </div>
                      <br />
                      {mul.mid.description2}
                      <br /><br />
                      {/* {mul.mid.description3} */}
                      {/* {mul.mid.description8} */}
                      {/* <br /><br /> */}
                      {/* {mul.mid.description9} */}
                      {/* <br /><br /> */}
                      {mul.mid.description10}
                      <br /><br />
                      <img src="../img/Home/retail_case3.jpg" style={{ width: "90%", marginLeft: "3%" }} alt="retail_introduction" />
                      <img src="../img/Home/retail_case1.png" style={{ width: "45%", marginLeft: "3%" }} alt="retail_introduction" />
                      <img src="../img/Home/retail_case2.png" style={{ width: "42%", marginLeft: "3%" }} alt="retail_introduction" />

                      <br /><br />
                      <br />
                      {/* <br /><br />
                      {lang === 'kr' ?
                        <img src="../img/Home/introduction2.png" style={{ width: "60%", marginLeft: "17%" }} alt="introduction2" /> :
                        <img src="../img/Home/introduction2_en.png" style={{ width: "60%", marginLeft: "17%" }} alt="introduction2" />
                      } */}
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="content">
                    <div className="head" style={{ marginBottom: "3%" }}>
                      {mul.bottom.head}
                    </div>
                    <div className="service">
                      {lang === 'kr' ?
                        <img src="../img/Home/cloud_service_kr_modified.png" style={{ width: "100%", marginLeft: "3%" }} alt="introduction2" /> :
                        <img src="../img/Home/cloud_service_en_modified.png" style={{ width: "100%", marginLeft: "3%" }} alt="introduction2" />
                      }
                      {/* <div className="box is-child bottom">
                        {mul.bottom.box1.main1} <br />
                        {mul.bottom.box1.main2}
                      </div>
                      <div className="bottomDes">
                        {mul.bottom.box1.description}
                      </div>

                      <div className="box is-child bottom">
                        {mul.bottom.box2.main1} <br />
                        {mul.bottom.box2.main2}
                      </div>
                      <div className="bottomDes">
                        {mul.bottom.box2.description}
                      </div>

                      <div className="box is-child bottom">
                        {mul.bottom.box3.main1} <br />
                        {mul.bottom.box3.main2}
                      </div>
                      <div className="bottomDes">
                        {mul.bottom.box3.description}
                      </div>

                      <div className="box is-child bottom">
                        {mul.bottom.box4.main1} <br />
                        {mul.bottom.box4.main2}
                      </div>
                      <div className="bottomDes">
                        {mul.bottom.box4.description}
                      </div>

                      <div className="box is-child bottom">
                        {mul.bottom.box5.main1} <br />
                        {mul.bottom.box5.main2}
                      </div>
                      <div className="bottomDes">
                        {mul.bottom.box5.description}
                      </div> */}
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "6em" }}>
                  <img className="foot_img" src="../img/Home/shinsegae_logo_eng.jpg" style={{ width: "30%", float: "" }} alt="foot_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div >
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  kr: PropTypes.object,
  en: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        kr={frontmatter.kr}
        en={frontmatter.en}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        kr {
          top {
            head
            description1
            description2
            description3
          }
          mid {
            retail_head
            description4
            description5
            description6
            description7
            retail_case
            description8
            description9
            description10
            head
            description1
            description2
            description3
            img_des1
            img_des2
            img_des3
          }
          bottom {
            head
            box1 {
              main1
              main2
              description
            }
            box2 {
              main1
              main2
              description
            }
            box3 {
              main1
              main2
              description
            }
            box4 {
              main1
              main2
              description
            }
            box5 {
              main1
              main2
              description
            }
          }
        }

        en {
          top {
            head
            description1
            description2
            description3
          }
          mid {
            retail_head
            description4
            description5
            description6
            description7
            retail_case
            description8
            description9
            description10
            head
            description1
            description2
            description3
            img_des1
            img_des2
            img_des3
          }
          bottom {
            head
            box1 {
              main1
              main2
              description
            }
            box2 {
              main1
              main2
              description
            }
            box3 {
              main1
              main2
              description
            }
            box4 {
              main1
              main2
              description
            }
            box5 {
              main1
              main2
              description
            }
          }
        }
      }
    }
  }
`
